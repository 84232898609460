import * as React from "react"
import { graphql } from "gatsby"

import { Container} from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Billboard from "../components/billboard"


const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <Seo title="404: Not found" />
    <Billboard image={props.data.heroImage.childImageSharp.fluid}/>
    <Container className="mt-5 text-center">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`;